import React from 'react';

import Layout from "../components/layouts/Layout";
import Seo from "../components/main/SEO";

import SectionLayoutGrid from "../components/styled-components/wrappers/SectionLayoutGrid";
import Col from "../components/styled-components/wrappers/Col";
import H2 from "../components/styled-components/typography/H2";
import P from "../components/styled-components/typography/P";
import LinkButton from "../components/styled-components/button/LinkButton";

const ThankYou = () => {
    return (
        <Layout title={"Kapcsolatfelvétel"}>
            <Seo title={"Balasys Blackowl - Kapcsolatfelvétel"} description={"A Balasys Blackowl kapcsolatfelvétel oldala."} keywords={"balasys, blackowl, kapcsolatfelvétel"} url={"/contact-success"}/>
            <SectionLayoutGrid id="contact-success">
                <Col full grid center>
                    <H2>
                        Sikeres kapcsolatfelvétel!
                    </H2>
                    <P>
                        Ha vissza szeretne térni a kezdőlapra, kérjük kattintson a 'Vissza' gombra.
                    </P>
                </Col>
                <Col full center>
                    <LinkButton to={"/"}>
                        Vissza
                    </LinkButton>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    );
};

export default ThankYou;